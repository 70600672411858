import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  date,
  helmet,
  prev,
  next,
}) => {
  const PostContent = contentComponent || Content;

  const [location, setLocation] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined" && window) {
      setLocation(window.location);
    }
  }, []);

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <Link
              className="is-size-6 has-text-weight-light has-text-grey"
              to="/blog"
            >
              <i className="fas fa-long-arrow-alt-left" /> Go to blog
            </Link>
            <h2 className="title is-size-3 has-text-weight-bold is-bold-light m-t-md">
              {title}
            </h2>
            <h3 className="subtitle is-size-6 has-text-weight-light has-text-grey">
              {date}
            </h3>
            <p>{description}</p>
            <PostContent className="has-text-justified" content={content} />
            {tags && tags.length ? (
              <div style={{ marginTop: "2rem" }}>
                {/* <h5 className="m-b-none">Tags</h5> */}
                <ul className="taglist m-t-sm">
                  {tags.map(tag => (
                    <li key={`${tag}tag`}>
                      <Link
                        className="tag is-light"
                        to={`/tags/${kebabCase(tag)}/`}
                      >
                        {tag}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            <div style={{ marginTop: "0.2rem" }}>
              {/* <h5 className="m-b-none">Share</h5> */}
              <ul className="taglist m-t-none">
                <div className=" social">
                  <a
                    title="email"
                    href={`mailto:?subject=Checkout OCCASIO&body=${location}`}
                    target="_blank"
                  >
                    <span
                      className="far fa-envelope"
                      alt="email"
                      style={{ width: "1em", height: "1em" }}
                    />
                  </a>
                  <a
                    title="linkedin"
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${location}`}
                    target="_blank"
                  >
                    <span
                      className="fab fa-linkedin-in"
                      alt="linkedin"
                      style={{ width: "1em", height: "1em" }}
                    />
                  </a>
                  <a
                    title="facebook"
                    // href={`https://www.facebook.com/sharer/sharer.php?u=${window.location}&t=your%20message`}
                    href={`https://www.facebook.com/sharer/sharer.php?u=${location}&hashtag=%23occasio&quote=Checkout%20OCCASIO%20post!`}
                    target="_blank"
                  >
                    <span
                      className="fab fa-facebook-f"
                      alt="Facebook"
                      style={{ width: "1em", height: "1em" }}
                    />
                  </a>
                  {/* <a title="whatsapp" href="https://whatsapp.com">
                    <span
                      className="fab fa-whatsapp"
                      alt="whatsapp"
                      style={{ width: "1em", height: "1em" }}
                    />
                  </a> */}
                  {/* <a title="skype" href="https://skype.com">
                    <span
                      className="fab fa-skype"
                      alt="skype"
                      style={{ width: "1em", height: "1em" }}
                    />
                  </a> */}
                  {/* <a title="viber" href="https://viber.com">
                    <span
                      className="fab fa-viber"
                      alt="viber"
                      style={{ width: "1em", height: "1em" }}
                    />
                  </a> */}
                  <a
                    title="twitter"
                    href={`http://twitter.com/intent/tweet?text=Checkout%20OCCASIO%20post!%20${location}&hashtags=occasio`}
                    target="_blank"
                  >
                    <span
                      className="fab fa-twitter"
                      alt="Twitter"
                      style={{ width: "1em", height: "1em" }}
                    />
                  </a>
                  {/* <a title="instagram" href="https://instagram.com">
                            <span
                          className="fab fa-instagram"
                              alt="Instagram"
                              style={{ width: "1em", height: "1em" }}
                            />
                          </a> */}
                </div>
              </ul>
            </div>
            <div
              className="flex-column-mobile"
              style={{
                marginTop: "0.8rem",
                marginBottom: "0.4rem",
                display: "flex",
                placeContent: "space-between",
              }}
            >
              <div className="">
                {prev && (
                  <Link className="has-text-grey" to={prev.fields.slug}>
                    <i className="fas fa-long-arrow-alt-left" /> previous
                    <h5>{prev.frontmatter.title}</h5>
                  </Link>
                )}
              </div>
              <div className="has-text-right-tablet">
                {next && (
                  <Link className="has-text-grey" to={next.fields.slug}>
                    next <i className="fas fa-long-arrow-alt-right" />
                    <h5>{next.frontmatter.title}</h5>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data, pageContext }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        prev={pageContext.prev}
        next={pageContext.next}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "DD.MM.YYYY")
        title
        description
        tags
      }
    }
  }
`;
